import { useContext, useState } from "react";
import TenantsGlobalStoreContext from "./TenantsGlobalStoreContext";

const TenantsGlobalStoreProvider = props => {
  const [refreshRequestCount, setRefreshRequestCount] = useState(true);
  const [requestCount, setRequestCount] = useState(0);

  const TenantsGlobalStoreContextValue = {
    requestCount: requestCount,
    updateRequestCount: setRequestCount,
    refreshRequestCount: refreshRequestCount,
    updateRefreshRequestCount: setRefreshRequestCount,
  };

  return (
    <TenantsGlobalStoreContext.Provider value={TenantsGlobalStoreContextValue}>
      {props.children}
    </TenantsGlobalStoreContext.Provider>
  );
};

export default TenantsGlobalStoreProvider;
export const useTenantsGlobalStore = () =>
  useContext(TenantsGlobalStoreContext);
