/**
 * Redirect to the subscription problem page.
 * @function goToSubscriptionProblemPage
 * This function takes a URL, a message, and an optional parameter indicating whether the email is not confirmed. It removes the "tokenInfo" from the localStorage, updates the browser history state with the provided message and email confirmation status, and reloads the page to navigate to the specified URL.
 * @param {string} url - The URL to navigate to.
 * @param {string} message - The message to be included in the history state.
 * @param {boolean} [emailNotConfirmed=false] - Optional. Indicates whether the email is not confirmed. Default is `false`.
 */

export function goToSubscriptionProblemPage(
  url,
  message,
  emailNotConfirmed = false
) {
  localStorage.removeItem("tokenInfo");
  const state = { message: message, emailNotConfirmed: emailNotConfirmed };
  history.pushState(state, "", url);
  history.go(0);
}
