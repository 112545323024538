import i18next from "i18next";
import showWarningMessage from "./alerts/showWarningMessage";
import showInfoMessage from "./alerts/showInfoMessage";
import showClosableToastAlert from "./alerts/showClosableToastAlert";

/**
 * Handle API errors, React unexpected errors and network errors
 * @function apiErrorHandler
 * @param {object} error - Contains information about the occurred error
 * @param {Function} handleError - It will handle React errors and API responses with status code 500
 * @param {boolean} isArrayBufferValue - Determines whether the error response is returned as an array buffer
 * @param {Function} confirmedFunction - It will be passed to showWarningMessage or showInfoMessage functions
 * @param {Function} externalErrorStateHandler - Used to update the error state at the component level instead of passing the error to handleError function
 */
export function apiErrorHandler(
  error,
  handleError,
  isArrayBufferValue,
  confirmedFunction,
  externalErrorStateHandler
) {
  const t = i18next.t;
  try {
    if (error.response) {
      if (error.response.status === 500) {
        showClosableToastAlert("error", t("we_are_sorry_something_went_wrong"));
      } else {
        if ([401, 403, 462, 463].includes(error.response.status)) {
          return;
        }

        let errMessage = isArrayBufferValue
          ? error.response.data.byteLength > 0
            ? JSON.parse(Buffer.from(error.response.data).toString("utf8"))
                ?.message
            : ""
          : error.response?.data?.message ||
            error.response?.data?.title ||
            error.response?.data ||
            "Bad request";

        if (error.response.status === 404 && !error.response?.data)
          errMessage = "API Not Found";

        externalErrorStateHandler
          ? externalErrorStateHandler(errMessage)
          : showWarningMessage(null, errMessage, confirmedFunction);
      }
    } else if (error.request) {
      let errorTitle = "Oops!";
      let errorMessage = error;
      if (error.request.status === 0) {
        errorTitle = t("network_error");
        errorMessage = t("please_check_network_connection_and_try_again");
      }
      externalErrorStateHandler
        ? externalErrorStateHandler(errorMessage)
        : showInfoMessage(errorTitle, errorMessage, confirmedFunction);
    } else {
      handleError(error);
    }
  } catch (err) {
    handleError(err);
  }
}
