import { useEffect } from "react";
import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useTenantsAuth } from "../../tenantsManagementStore/TenantsAuthProvider";

function IdleAdminPrompt() {
  const [remainingTime, setRemainingTime] = useState(59);

  const { idleTimerPrompted, updateIdleTimerPrompted, logout } =
    useTenantsAuth();

  let timeoutInMinutes = 1000;
  if (process.env.NODE_ENV === "production") {
    timeoutInMinutes = 14;
  }
  const timeout = timeoutInMinutes * 60 * 1000;
  const promptBeforeIdle = 59 * 1000;

  const onPrompt = () => {
    updateIdleTimerPrompted(true);
  };

  const onIdle = () => {
    logout();
  };

  const onMessage = () => {
    if (idleTimerPrompted) updateIdleTimerPrompted(false);
  };

  const { isPrompted, getRemainingTime, activate, message } = useIdleTimer({
    timeout: timeout,
    promptBeforeIdle: promptBeforeIdle,
    onPrompt: onPrompt,
    onIdle: onIdle,
    onMessage: onMessage,
    crossTab: false,
  });

  const stillHereHandler = () => {
    activate();
    message("reactivate", true);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        const newRemainingTime = Math.ceil(getRemainingTime() / 1000);
        setRemainingTime(newRemainingTime);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  if (!idleTimerPrompted) return <></>;

  return (
    <div className="animate-fadeIn w-full h-screen absolute left-0 top-0 z-999 bg-primary/95 dark:bg-dark-12/95 text-white flex flex-col items-center justify-center">
      <div className="font-bold">
        <span className="rounded shadow-xl text-7xl leading-none">Z</span>
        <span className="rounded shadow-xl text-5xl leading-none text-main-gray dark:text-dark-main-white relative bottom-16 left-1">
          Z
        </span>
        <span className="rounded shadow-xl text-5xl leading-none relative bottom-4 left-3">
          Z
        </span>
      </div>
      <p className="shadow-xl my-7 text-2xl">
        <span>Logging you out in</span>
        <span className="mx-2 font-bold text-main-gray dark:text-dark-main-white">
          <span className="animate-pulse0">{remainingTime}</span>
        </span>
        <span>Seconds</span>
      </p>
      <button
        onClick={stillHereHandler}
        className="shadow-xl text-base font-semibold px-4 py-1 bg-gray-50 text-main-gray transition-colors duration-200 hover:bg-main-gray hover:text-white rounded"
      >
        I am still here
      </button>
    </div>
  );
}

export default IdleAdminPrompt;
