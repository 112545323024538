const controllerName = "/Account";

export default {
  loginConfig: () => {
    return {
      method: "post",
      url: `${controllerName}/Login`,
    };
  },
  sendVerificationCodeConfig: (userId, verificationType, sendBy) => {
    return {
      method: "post",
      url: `${controllerName}/SendVerificationCode?userId=${userId}&verificationType=${verificationType}&sendBy=${sendBy}`,
    };
  },
  validateUserCodeConfig: (userId, code, verificationType) => {
    return {
      method: "post",
      url: `${controllerName}/ValidateUserCode?userId=${userId}&code=${code}&verificationType=${verificationType}`,
    };
  },
  getUserConfig: keyrowd => {
    return {
      method: "get",
      url: `${controllerName}/GetUser?keyrowd=${keyrowd}`,
    };
  },
  resetPasswordConfig: (userId, token, newPassword) => {
    return {
      method: "post",
      url: `${controllerName}/ResetPassword?userId=${userId}&token=${token}&newPassword=${newPassword}`,
    };
  },
  updatePasswordConfig: (userId, newPassword) => {
    return {
      method: "post",
      url: `${controllerName}/UpdatePassword?userId=${userId}&newPassword=${newPassword}`,
    };
  },
  changeUserPasswordConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/ChangeUserPassword`,
      data: payload,
    };
  },
  changeLanguageConfig: langCode => {
    return {
      method: "post",
      url: `${controllerName}/ChangeLanguage?langCode=${langCode}`,
    };
  },
  getUserAccordingToLocationsConfig: (
    payload,
    showAllUsers,
    projectId = null
  ) => {
    return {
      method: "post",
      url: `${controllerName}/GetUserAccordingToLocations?${
        projectId ? `projectId=${projectId}&` : ""
      }showAllUsers=${showAllUsers}`,
      data: payload,
    };
  },
  getUsersDetailsConfig: (skip, limit, keyword, accountStatus) => {
    return {
      method: "get",
      url: `${controllerName}/GetUsersDetails?withNotActiveUsers=true&skip=${skip}&limit=${limit}${
        keyword ? `&keyword=${encodeURIComponent(keyword)}` : ""
      }${
        accountStatus === 0 || accountStatus
          ? `&AccountStatus=${accountStatus}`
          : ""
      }`,
    };
  },
  createUserConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/CreateUser`,
      data: payload,
    };
  },
  updateUserConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/UpdateUser`,
      data: payload,
    };
  },
  changeAccountStatusConfig: (userId, status) => {
    return {
      method: "post",
      url: `${controllerName}/ChangeAccountStatus?uId=${userId}&status=${status}`,
    };
  },
  unlockAccountConfig: userId => {
    return {
      method: "post",
      url: `${controllerName}/UnlockAccount?id=${userId}`,
    };
  },
  getUsersConfig: (withAnonymousUser = false, activityId = null) => {
    return {
      method: "get",
      url: `${controllerName}/GetUsers?withAnonymousUser=${withAnonymousUser}${
        activityId ? `&activityId=${activityId}` : ""
      }`,
    };
  },
  deleteAccountConfig: userId => {
    return {
      method: "post",
      url: `${controllerName}/DeleteAccount?userId=${userId}`,
    };
  },
  getProjectRolesConfig: userId => {
    return {
      method: "get",
      url: `${controllerName}/GetProjectRoles?userId=${userId}`,
    };
  },
  getRolesDetailsConfig: (skip, limit, keyword) => {
    return {
      method: "get",
      url: `${controllerName}/GetRolesDetails?skip=${skip}&limit=${limit}&keyword=${keyword}`,
    };
  },
  getRolesConfig: () => {
    return {
      method: "get",
      url: `${controllerName}/GetRoles`,
    };
  },
  deleteRoleConfig: roleId => {
    return {
      method: "delete",
      url: `${controllerName}/DeleteRole?id=${roleId}`,
    };
  },
  addUsersToRoleConfig: (payload, roleId) => {
    return {
      method: "post",
      url: `${controllerName}/AddUsersToRole?roleId=${roleId}`,
      data: payload,
    };
  },
  addRolesToUserConfig: (payload, userId) => {
    return {
      method: "post",
      url: `${controllerName}/AddRolesToUser?userId=${userId}`,
      data: payload,
    };
  },
  addRoleConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/AddRole`,
      data: payload,
    };
  },
  updateRoleConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/UpdateRole`,
      data: payload,
    };
  },
  createPermissionsConfig: (payload, roleId) => {
    return {
      method: "post",
      url: `${controllerName}/CreatePermissions?roleId=${roleId}`,
      data: payload,
    };
  },
  getPermissionsConfig: roleId => {
    return {
      method: "get",
      url: `${controllerName}/GetPermissions?roleId=${roleId}`,
    };
  },
};
