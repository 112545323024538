import { getSubdomain } from "tldts";

/**
 * Get the subdomain name based on the current environment and window location.
 * @function getSubdomainName
 * This function utilizes the `getSubdomain` utility to extract the subdomain from the window location hostname. It takes into account the valid hosts based on the environment (e.g., localhost in non-production environments) and returns the subdomain if present, or an empty string if not.
 * @returns {string} - The subdomain name extracted from the window location hostname or an empty string if no subdomain is found.
 */
export function getSubdomainName() {
  const validHosts = ["localhost"];
  // const validHosts =
  //   process.env.NODE_ENV === "production" ? null : ["localhost"];
  const subdomain = getSubdomain(window.location.hostname, {
    validHosts: validHosts,
  });
  return subdomain?.toLowerCase() || "";
}
