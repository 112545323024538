import React from "react";

const TenantsGlobalStoreContext = React.createContext({
  /* eslint no-unused-vars:off */
  requestCount: 0,
  updateRequestCount: newRequestCount => {},
  refreshRequestCount: true,
  updateRefreshRequestCount: refresh => {},
});

export default TenantsGlobalStoreContext;
