import React from "react";

const TenantsAuthContext = React.createContext({
  /* eslint no-unused-vars:off */
  token: "",
  isLoggedIn: false,
  login: token => {},
  logout: () => {},
  idleTimerPrompted: false,
  updateIdleTimerPrompted: isPrompted => {},
});

export default TenantsAuthContext;
