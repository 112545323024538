import "./services/initializeSentry";
import "./services/i18n";
import "./index.css";
import { Suspense } from "react";
import React from "react";
import ReactDOM from "react-dom";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./components/UI/Errors/ErrorFallback";
import AuthProvider from "./store/AuthProvider";
import TenantsAuthProvider from "./tenantsManagementStore/TenantsAuthProvider";
import GlobalStoreProvider from "./store/GlobalStoreProvider";
import TenantsGlobalStoreProvider from "./tenantsManagementStore/TenantsGlobalStoreProvider";
import { getSubdomainName } from "./utils/getSubdomainName";
import BladesSpinner from "./components/UI/Loaders/BladesSpinner";
import App from "./App";
import TenantsManagementApp from "./TenantsManagementApp";

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}

const subdomain = getSubdomainName();

if (subdomain?.toLocaleLowerCase() === "admin") {
  ReactDOM.render(
    <React.StrictMode>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <TenantsAuthProvider>
          <TenantsGlobalStoreProvider>
            <BrowserRouter>
              <Suspense
                fallback={
                  <BladesSpinner
                    classes="bg-gray-100 dark:bg-dark-12"
                    spinnerPosition="relative"
                    spinnerHeight="h-screen"
                  />
                }
              >
                <TenantsManagementApp />
              </Suspense>
            </BrowserRouter>
          </TenantsGlobalStoreProvider>
        </TenantsAuthProvider>
      </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById("root")
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AuthProvider>
          <GlobalStoreProvider>
            <BrowserRouter>
              <Suspense
                fallback={
                  <BladesSpinner
                    classes="bg-gray-100 dark:bg-dark-12"
                    spinnerPosition="relative"
                    spinnerHeight="h-screen"
                  />
                }
              >
                <App />
              </Suspense>
            </BrowserRouter>
          </GlobalStoreProvider>
        </AuthProvider>
      </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById("root")
  );
}
