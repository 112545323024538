import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { getBackendApiUrl } from "../utils/getBackendApiUrl";

const backendApiUrl = getBackendApiUrl();

const initOptions = {
  debug: process.env.NODE_ENV !== "production",
  load: "languageOnly",
  fallbackLng: "en",
  detection: {
    order: ["localStorage", "htmlTag"],
    caches: ["localStorage"],
  },
  backend: {
    loadPath: `${backendApiUrl}/Settings/GetTranslations?langCode={{lng}}`,
  },
  lowerCaseLng: true,
};

const initCallback = (err, t) => {
  if (err) {
    console.warn("Failed to initialize translations");
  } else {
    document.title = t("app_title");
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init(initOptions, initCallback);

export default i18n;
