import React from "react";

const AuthContext = React.createContext({
  /* eslint no-unused-vars:off */
  tokenInfo: {
    token: "",
    expiresIn: "",
    userRole: "",
    userFullName: "",
    userName: "",
    userEmail: "",
    userId: "",
    userMobileNumber: "",
    userHometown: "",
    message: "",
    userPermissions: [],
    userRoles: [],
    gracePeriodStartDate: "",
  },
  userId: "",
  isLoggedIn: false,
  isSuperAdmin: false,
  login: tokenInfo => {},
  logout: () => {},
  hasPermission: permissionToCheck => {},
  idleTimerPrompted: false,
  updateIdleTimerPrompted: isPrompted => {},
  initializeAxiosWithoutLogin: token => {},
  checkGracePeriodActivation: false,
});

export default AuthContext;
