import { Loader } from "@googlemaps/js-api-loader";
import axios from "axios";
import { useEffect, useRef } from "react";
import { useContext } from "react";
import { useState } from "react";
import { generateUserPermissionsObject } from "../lib/PermissionsHelpers";
import AuthContext from "./AuthContext";
import initializeAxios from "../services/initializeAxios";
import { getBackendApiUrl } from "../utils/getBackendApiUrl";
import { goToSubscriptionProblemPage } from "../utils/goToSubscriptionProblemPage";
import { isExpiredDate } from "../utils/dates/isExpiredDate";
import { storeTenantInfo } from "../utils/storeTenantInfo";
import showClosableToastAlert from "../utils/alerts/showClosableToastAlert";
import { getURLOfRedirectPages } from "../utils/getURLOfRedirectPages";

const GOOGLE_MAPS_API_KEY = window.__ENV__.GOOGLE_MAPS_API_KEY;
const googleMapLibraries = ["marker"];

const AuthProvider = props => {
  let initialToken = null;
  try {
    const storedTokenInfo = localStorage.getItem("tokenInfo");
    if (storedTokenInfo) initialToken = JSON.parse(storedTokenInfo);
  } catch (err) {
    console.error("Invalid token");
  }

  const [tokenInfo, setTokenInfo] = useState(initialToken);
  const [userPermissions, setUserPermissions] = useState([]);
  const [idleTimerPrompted, setIdleTimerPrompted] = useState(false);

  const googleMapLoaderRef = useRef(null);

  const isUserLoggedIn = !!tokenInfo;
  const isSuperAdmin = tokenInfo?.userRole === "Super Admin";

  const loader = new Loader({
    apiKey: GOOGLE_MAPS_API_KEY,
    libraries: googleMapLibraries,
    version: "weekly",
  });

  if (loader && !googleMapLoaderRef?.current) {
    googleMapLoaderRef.current = loader;
    loader.importLibrary("maps").catch(e => {
      console.error(e);
      showClosableToastAlert(
        "error",
        "Google Maps failed to load",
        "This may result in the maps not working correctly, please refresh the page"
      );
    });
  }

  const logoutHandler = async () => {
    setTokenInfo(null);
    localStorage.removeItem("tokenInfo");
    sessionStorage.removeItem("currentProject");
    delete axios.defaults.headers.common["Authorization"];
    //reset User Permissions
    setUserPermissions([]);
    setIdleTimerPrompted(false);
  };

  const loginHandler = tokenInfo => {
    localStorage.setItem("tokenInfo", JSON.stringify(tokenInfo));
    setTokenInfo(tokenInfo);
  };

  const hasPermission = permission => {
    if (userPermissions && userPermissions.length > 0) {
      return userPermissions.includes(permission);
    }
    return false;
  };

  const initializeAxiosWithoutLoginHandler = token => {
    try {
      const backendApiUrl = getBackendApiUrl();
      const { accessDeniedPageUrl, subscriptionProblemPageUrl } =
        getURLOfRedirectPages();
      axios.defaults.baseURL = backendApiUrl;
      axios.defaults.headers.common = {
        Authorization: `bearer ${token}`,
      };
      axios.interceptors.response.use(
        res => {
          storeTenantInfo(res);
          return res;
        },
        err => {
          if ([403].includes(err.response?.status)) {
            window.location.replace(accessDeniedPageUrl);
          } else if ([462].includes(err.response?.status)) {
            const message = err.response.data.message;
            goToSubscriptionProblemPage(subscriptionProblemPageUrl, message);
          } else if ([463].includes(err.response?.status)) {
            const message = err.response.data.message;
            goToSubscriptionProblemPage(subscriptionProblemPageUrl, message);
          }
          return Promise.reject(err);
        }
      );
    } catch (err) {
      console.error(`Initialize axios failed | error: ${err}`);
    }
  };

  const checkGracePeriodActivation = () => {
    const gracePeriodStartDate = tokenInfo?.gracePeriodStartDate;
    const isActive = gracePeriodStartDate
      ? isExpiredDate(gracePeriodStartDate)
      : false;
    return isActive;
  };

  useEffect(() => {
    initializeAxios(isUserLoggedIn, tokenInfo, logoutHandler);

    if (isUserLoggedIn) {
      const frontUserPermissions = generateUserPermissionsObject(
        tokenInfo?.userPermissions,
        isSuperAdmin
      );
      setUserPermissions(frontUserPermissions);
    }
  }, [isUserLoggedIn, tokenInfo, isSuperAdmin]);

  const authContextValue = {
    tokenInfo: tokenInfo,
    userId: tokenInfo?.userId,
    isLoggedIn: isUserLoggedIn,
    isSuperAdmin: isSuperAdmin,
    login: loginHandler,
    logout: logoutHandler,
    hasPermission: hasPermission,
    idleTimerPrompted: idleTimerPrompted,
    updateIdleTimerPrompted: setIdleTimerPrompted,
    initializeAxiosWithoutLogin: initializeAxiosWithoutLoginHandler,
    checkGracePeriodActivation: checkGracePeriodActivation,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
export const useAuth = () => useContext(AuthContext);
