import React from "react";

const GlobalStoreContext = React.createContext({
  /* eslint no-unused-vars:off */
  favorites: [],
  getFavoriteItem: (objectId, targetPage) => {},
  addFavoriteItem: newFavoriteItem => {},
  removeFavoriteItem: id => {},
  updateFavorites: newFavorites => {},
  projectsData: [],
  getProjectById: id => {},
  setProjectsData: newProjectsData => {},
  updateProjectsData: (
    newProjectData = null,
    index = null,
    projectIdToDelete = null
  ) => {},
  numOfProjects: 0,
  currentProjectId: "",
  updateCurrentProjectId: currentProjectId => {},
  theme: "",
  updateTheme: newTheme => {},
});

export default GlobalStoreContext;
