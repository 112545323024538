import { lazy, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useAuth } from "./store/AuthProvider";
import Login from "./pages/login/Login";

const Home = lazy(() => import("./pages/Home"));
const CollectSurveyData = lazy(() =>
  import("./pages/dataCollection/CollectSurveyData")
);
const AnonymousDataCollection = lazy(() =>
  import("./pages/dataCollection/AnonymousDataCollection")
);
const PreviewSurvey = lazy(() => import("./pages/survey/PreviewSurvey"));
const PrivacyPolicy = lazy(() => import("./pages/settings/PrivacyPolicy"));
const UserViewManagement = lazy(() =>
  import("./pages/survey/UserViewManagement")
);
const TenantSignUp = lazy(() => import("./pages/login/TenantSignUp"));
const ManageSubscription = lazy(() =>
  import("./pages/other/ManageSubscription")
);
const SubscriptionProblem = lazy(() =>
  import("./pages/general/SubscriptionProblem")
);
const Documentation = lazy(() => import("./pages/other/Documentation"));
const AccessDenied = lazy(() => import("./pages/general/AccessDenied"));
const PageNotFound = lazy(() => import("./pages/general/PageNotFound"));
const ResetPassword = lazy(() => import("./pages/login/ResetPassword"));
const ConfirmResetPassword = lazy(() =>
  import("./pages/login/ConfirmResetPassword")
);
const ConfirmAccount = lazy(() => import("./pages/login/ConfirmAccount"));
const ForgotPassword = lazy(() => import("./pages/login/ForgotPassword"));
const CampaignDataCollection = lazy(() =>
  import("./pages/dataCollection/CampaignDataCollection")
);
const CampaignInterviewEditor = lazy(() =>
  import(
    "./pages/dataCollection/CampaignInterviewEditor/CampaignInterviewEditor"
  )
);
const RejectionFeedback = lazy(() => import("./pages/RejectionFeedback"));
const AnonymousInterviewEditor = lazy(() =>
  import(
    "./pages/dataCollection/AnonymousInterviewEditor/AnonymousInterviewEditor"
  )
);

import "tippy.js/dist/tippy.css";
import "simplebar-react/dist/simplebar.min.css";
import themes from "devextreme/ui/themes";

import { checkRMTeamDomain } from "./utils/checkRMTeamDomain";
import { DATA_COLLECTION_MODE } from "./lib/Constants/DataCollectionConstants";

function App() {
  const loginURL = "/login";
  const mainPageURL = "/projects";

  const { tokenInfo, isSuperAdmin, hasPermission } = useAuth();
  const isUserLoggedIn = !!tokenInfo;
  const isRMTeam = checkRMTeamDomain();

  const hasCampaignsManagementPermission = hasPermission("CampaignsManagement");
  const hasPreviewEntityTypesPermission = hasPermission("PreviewEntityTypes");
  const hasPreviewEntityRecordsPermission = hasPermission(
    "PreviewEntityRecords"
  );

  useEffect(() => {
    const theme = localStorage.theme;
    if (theme === "dark") {
      const htmlElement = window.document.documentElement;
      htmlElement.classList.add(theme);
      themes.current("material.blue.dark.compact");
    } else {
      themes.current("material.blue.light.compact");
    }
  }, []);

  return (
    <Switch>
      <Route path="/sign-up" exact>
        {isUserLoggedIn && <Redirect to={mainPageURL} />}
        {!isUserLoggedIn && <TenantSignUp />}
      </Route>
      <Route path="/documentation" exact>
        {isUserLoggedIn && <Documentation />}
        {!isUserLoggedIn && <Redirect to={loginURL} />}
      </Route>
      <Route path="/access-denied" exact>
        <AccessDenied />
      </Route>
      <Route path="/" exact>
        {isUserLoggedIn && <Redirect to={mainPageURL} />}
        {!isUserLoggedIn && <Redirect to={loginURL} />}
      </Route>
      <Route path={loginURL} exact>
        {isUserLoggedIn && <Redirect to={mainPageURL} />}
        {!isUserLoggedIn && <Login />}
      </Route>
      <Route path="/confirm-account" exact>
        {isUserLoggedIn && <Redirect to={mainPageURL} />}
        {!isUserLoggedIn && <ConfirmAccount />}
      </Route>
      <Route path="/forgot-password" exact>
        {isUserLoggedIn && <Redirect to={mainPageURL} />}
        {!isUserLoggedIn && <ForgotPassword />}
      </Route>
      <Route path="/confirm-reset-password" exact>
        {isUserLoggedIn && <Redirect to={mainPageURL} />}
        {!isUserLoggedIn && <ConfirmResetPassword />}
      </Route>
      <Route path="/reset-password" exact>
        {isUserLoggedIn && <Redirect to={mainPageURL} />}
        {!isUserLoggedIn && <ResetPassword />}
      </Route>
      <Route path="/users-management">
        {isUserLoggedIn && <Home />}
        {!isUserLoggedIn && <Redirect to={loginURL} />}
      </Route>
      <Route path="/roles-management">
        {isUserLoggedIn && <Home />}
        {!isUserLoggedIn && <Redirect to={loginURL} />}
      </Route>
      <Route path="/campaigns-management">
        {isUserLoggedIn && hasCampaignsManagementPermission && <Home />}
        {isUserLoggedIn && !hasCampaignsManagementPermission && (
          <AccessDenied />
        )}
        {!isUserLoggedIn && <Redirect to={loginURL} />}
      </Route>
      <Route path="/create-campaign">
        {isUserLoggedIn && hasCampaignsManagementPermission && <Home />}
        {isUserLoggedIn && !hasCampaignsManagementPermission && (
          <AccessDenied />
        )}
        {!isUserLoggedIn && <Redirect to={loginURL} />}
      </Route>
      <Route path="/resume-draft-campaign">
        {isUserLoggedIn && hasCampaignsManagementPermission && <Home />}
        {isUserLoggedIn && !hasCampaignsManagementPermission && (
          <AccessDenied />
        )}
        {!isUserLoggedIn && <Redirect to={loginURL} />}
      </Route>
      <Route path="/view-campaign-details">
        {isUserLoggedIn && hasCampaignsManagementPermission && <Home />}
        {isUserLoggedIn && !hasCampaignsManagementPermission && (
          <AccessDenied />
        )}
        {!isUserLoggedIn && <Redirect to={loginURL} />}
      </Route>
      <Route path="/entity-types-management">
        {isUserLoggedIn && hasPreviewEntityTypesPermission && <Home />}
        {isUserLoggedIn && !hasPreviewEntityTypesPermission && <AccessDenied />}
        {!isUserLoggedIn && <Redirect to={loginURL} />}
      </Route>
      <Route path="/entity-records-management">
        {isUserLoggedIn && hasPreviewEntityRecordsPermission && <Home />}
        {isUserLoggedIn && !hasPreviewEntityRecordsPermission && (
          <AccessDenied />
        )}
        {!isUserLoggedIn && <Redirect to={loginURL} />}
      </Route>
      <Route path="/entity-related-surveys">
        {isUserLoggedIn && hasPreviewEntityTypesPermission && <Home />}
        {isUserLoggedIn && !hasPreviewEntityTypesPermission && <AccessDenied />}
        {!isUserLoggedIn && <Redirect to={loginURL} />}
      </Route>
      <Route path="/contacts-management">
        {isUserLoggedIn && <Home />}
        {!isUserLoggedIn && <Redirect to={loginURL} />}
      </Route>
      <Route path="/audit-log">
        {isUserLoggedIn && <Home />}
        {!isUserLoggedIn && <Redirect to={loginURL} />}
      </Route>
      <Route path="/views/:viewTitle/:token" exact>
        <UserViewManagement />
      </Route>
      <Route path="/views">
        {isUserLoggedIn && <Home />}
        {!isUserLoggedIn && <Redirect to={loginURL} />}
      </Route>
      <Route path="/project-users/:projectTitle">
        {isUserLoggedIn && <Home />}
        {!isUserLoggedIn && <Redirect to={loginURL} />}
      </Route>
      <Route path="/activity-users/:activityTitle">
        {isUserLoggedIn && <Home />}
        {!isUserLoggedIn && <Redirect to={loginURL} />}
      </Route>
      <Route path="/settings">
        {isUserLoggedIn && <Home />}
        {!isUserLoggedIn && <Redirect to={loginURL} />}
      </Route>
      <Route path={mainPageURL}>
        {isUserLoggedIn && <Home />}
        {!isUserLoggedIn && <Redirect to={loginURL} />}
      </Route>
      <Route path="/survey/:surveyTitle/collect-data">
        {isUserLoggedIn && <CollectSurveyData />}
        {!isUserLoggedIn && <Redirect to={loginURL} />}
      </Route>
      <Route path="/survey/:surveyTitle/review" exact>
        {isUserLoggedIn && <PreviewSurvey />}
        {!isUserLoggedIn && <Redirect to={loginURL} />}
      </Route>
      <Route path="/survey/:surveyTitle">
        {isUserLoggedIn && <Home />}
        {!isUserLoggedIn && <Redirect to={loginURL} />}
      </Route>
      {!isRMTeam && (
        <Route path="/admin/manage-subscription">
          {isUserLoggedIn && isSuperAdmin && <Home />}
          {isUserLoggedIn && !isSuperAdmin && <AccessDenied />}
          {!isUserLoggedIn && <Redirect to={loginURL} />}
        </Route>
      )}
      {!isRMTeam && (
        <Route path="/manage-subscription">
          {isUserLoggedIn && isSuperAdmin && <ManageSubscription />}
          {isUserLoggedIn && !isSuperAdmin && <AccessDenied />}
          {!isUserLoggedIn && <Redirect to={loginURL} />}
        </Route>
      )}
      <Route path="/subscription-problem" exact>
        <SubscriptionProblem />
      </Route>
      <Route path="/privacy-policy">
        <PrivacyPolicy />
      </Route>
      <Route path="/collect-data/:tokenId" exact>
        <AnonymousDataCollection />
      </Route>
      <Route path="/campaign-data-collect/:token" exact>
        <CampaignDataCollection
          dataCollectionMode={DATA_COLLECTION_MODE.campaignInterview}
        />
      </Route>
      <Route path="/interview/:interviewId/:token" exact>
        <CampaignInterviewEditor />
      </Route>
      <Route path="/edit-interview/:interviewId/:token" exact>
        <AnonymousInterviewEditor />
      </Route>
      <Route path="/rejection-feedback/:token" exact>
        <RejectionFeedback />
      </Route>
      <Route path="*">
        <PageNotFound />
      </Route>
    </Switch>
  );
}

export default App;
