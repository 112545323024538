import { useCallback, useContext, useState } from "react";
import GlobalStoreContext from "./GlobalStoreContext";
import showClosableToastAlert from "../utils/alerts/showClosableToastAlert";

const GlobalStoreProvider = props => {
  const [favorites, setFavorites] = useState([]);
  const [projectsData, setProjectsData] = useState([]);
  const storedProjectId = sessionStorage.getItem("currentProject");
  const [currentProjectId, setCurrentProjectId] = useState(storedProjectId);
  const [theme, setTheme] = useState(
    localStorage.theme === "dark" ? "dark" : "light"
  );

  const numOfProjects = projectsData.length;

  const getProjectById = useCallback(
    id => {
      const projectData = projectsData.find(
        projectData => projectData.id === id
      );
      return projectData;
    },
    [projectsData]
  );

  const updateProjectsData = useCallback(
    (newProjectData = null, index = null, projectIdToDelete = null) => {
      try {
        let newProjectsData = [...projectsData];
        if (projectIdToDelete) {
          //Delete project
          const filterdNewProjectsData = newProjectsData.filter(
            projectData => projectData.id !== projectIdToDelete
          );
          newProjectsData = filterdNewProjectsData.map((projectData, index) => {
            return {
              ...projectData,
              orderNum: index,
            };
          });
        } else {
          if (index !== null) {
            //Update project
            newProjectsData[index] = newProjectData;
          } else {
            //Create project
            newProjectsData.push(newProjectData);
          }
        }
        setProjectsData(newProjectsData);
      } catch (err) {
        showClosableToastAlert("error", "Action is not completed", "");
      }
    },
    [projectsData]
  );

  const getFavoriteItem = useCallback(
    (objectId, targetPage) => {
      const favoriteItem = favorites.find(
        favorite =>
          (!favorite.objectId || favorite.objectId === objectId) &&
          favorite.targetPage === targetPage
      );

      return favoriteItem;
    },
    [favorites]
  );

  const addFavoriteItem = useCallback(
    newFavoriteItem => {
      const newFavorites = [newFavoriteItem, ...favorites];
      setFavorites(newFavorites);
    },
    [favorites]
  );

  const removeFavoriteItem = useCallback(
    id => {
      const filteredFavorites = favorites.filter(
        favorite => favorite.id !== id
      );
      setFavorites(filteredFavorites);
    },
    [favorites]
  );

  const GlobalStoreContextValue = {
    favorites: favorites,
    getFavoriteItem: getFavoriteItem,
    addFavoriteItem: addFavoriteItem,
    removeFavoriteItem: removeFavoriteItem,
    updateFavorites: setFavorites,
    projectsData: projectsData,
    getProjectById: getProjectById,
    setProjectsData: setProjectsData,
    updateProjectsData: updateProjectsData,
    numOfProjects: numOfProjects,
    currentProjectId: currentProjectId,
    updateCurrentProjectId: setCurrentProjectId,
    theme: theme,
    updateTheme: setTheme,
  };

  return (
    <GlobalStoreContext.Provider value={GlobalStoreContextValue}>
      {props.children}
    </GlobalStoreContext.Provider>
  );
};

export default GlobalStoreProvider;
export const useGlobalStore = () => useContext(GlobalStoreContext);
